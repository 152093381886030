<template>
  <div>
    <v-container>
      <h1 class="pb-6">Add Document</h1>

      <v-card rounded="lg" class="pa-6">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <h4>Upload An Image</h4>
              <v-file-input outlined prepend-icon="mdi-camera"></v-file-input>
            </v-col>
            <v-col cols="12" md="6">
              <h4>Document</h4>
              <v-file-input outlined></v-file-input>
            </v-col>
            <v-col cols="12">
              <h4>Select Document Type</h4>
              <v-select outlined></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <div class="d-flex justify-center">
        <v-card width="300" class="ma-8 d-flex justify-center" rounded="lg">
          <div class="d-flex flex-column">
            <img
              width="140"
              class="py-8"
              src="/img/my_documents/add_document.svg"
            />
            <h3 class="pb-6">Add Document</h3>
          </div>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
